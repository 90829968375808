
@font-face {
  font-family: 'vapor';
  src: url('/vaporFont.ttf')
}

@font-face {
  font-family: 'streamster';
  src: url('/Streamster.ttf')
}

html {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  font-family: 'vapor', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: black;
  text-decoration: none;
}

.double {
  text-shadow: -2px 0px 0px cyan, 2px 0px 0px magenta;
}
.vaporwave {
  letter-spacing: 10px !important;
  font-weight: 200 !important;
  text-transform: uppercase;
  font-family: 'vapor' !important;
}
.no-select {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}
